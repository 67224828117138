import { TabItem } from "@/types/sponsorship";
import { defineComponent, PropType } from "vue";

export const Tab = defineComponent({
  props: {
    text: { type: String, required: true },
    class: { type: String },
    onRenderAction: { type: Function },
    onClick: { type: Function as PropType<(payload: MouseEvent) => void> },
  },
  render() {
    return (
      <div class={`box-heading-x flex w-full justify-between items-center ${this.class}`} onClick={this.onClick}>
        <h2 class="pb-5">{this.text}</h2>
        {this.onRenderAction?.()}
      </div>
    );
  },
});

export const ActiveTab = defineComponent({
  props: {
    items: { type: Array as PropType<TabItem[]>, required: true },
    activeIndex: { type: Number, required: true },
    onSelectTab: { type: Function as PropType<(index: number) => void> },
    tabClass: { type: String },
  },
  methods: {
    classes(item: TabItem): string {
      return [item.class, this.activeIndex === item.index ? " active" : ""].filter((item) => !!item).join(" ");
    },
  },
  render() {
    return (
      <header class="box-heading-x active-tabs flex w-full justify-start items-center">
        {this.items.map((item) => (
          <h2 key={item.index} class={`py-5 cursor-pointer px-8 ${this.classes(item)}`} onClick={() => this.onSelectTab?.(item.index)}>
            {item.title}
          </h2>
        ))}
      </header>
    );
  },
});
