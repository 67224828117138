import { defineComponent } from "vue";

export const Radio = defineComponent({
  name: "Checkbox",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [String, Number],
    },
    label: {
      type: String,
      defaultValue: "",
    },
    controlClass: {
      type: String,
      defaultValue: "",
    },
    disabled: {
      type: Boolean,
      defaultValue: false,
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    handleChange() {
      this.$emit("update:modelValue", this.value);
    },
  },
  render() {
    return (
      <label class={`radio-container relative ${this.controlClass}`}>
        <span>{this.label}</span>
        <input type="checkbox" checked={this.modelValue === this.value} onChange={this.handleChange} disabled={this.disabled} />
        <span class="checkmark absolute" />
      </label>
    );
  },
});
