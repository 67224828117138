import { get } from "js-cookie";
import { fromFetch } from "rxjs/fetch";
import { concatMap, map } from "rxjs/operators";
import { ErrorData } from "@/types/http";
const DEFAULT_TOKEN_KEY = "auth.token";

export default {
  install: (app: any, options: any) => {
    app.config.globalProperties.$http = {
      handleReponse(response: Response) {
        return response
          .json()
          .then((data) => {
            if (response.status === 401) {
              app.config.globalProperties.$auth.logout();
            }

            return data;
          })
          .catch(() => {
            throw new Error(
              JSON.stringify({
                errorCode: response.status,
                message: response.statusText,
              }),
            );
          });
      },
      getErrorData(error: Error) {
        try {
          const failRes: ErrorData = JSON.parse(error.message);
          return failRes;
        } catch {
          return {
            errorCode: 12029,
            message: "Network Error",
          };
        }
      },
      throwMessage(data: any) {
        if ("success" in data === false) {
          return;
        }

        if (!data.success) {
          const message = data && data.errors && data.errors.length > 0 ? data.errors[0]?.message || data.message : data.message;

          throw new Error(
            JSON.stringify({
              message: message,
              errorCode: data?.code,
            }),
          );
        }
      },
      get<T>(url: string) {
        return fromFetch(`${options.endpoint}${url}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${get(DEFAULT_TOKEN_KEY)}`,
          },
        }).pipe(
          concatMap((response) => {
            // 2 case when response fail
            // 1: Error network => solve in cacth block
            // 2: Server error => solve in map operator
            return this.handleReponse(response);
          }),
          map(
            (data: any): T => {
              this.throwMessage(data);

              return data;
            },
          ),
        );
      },
      post<T>(url: string, rqOptions?: RequestInit) {
        const headers = new Headers(rqOptions?.headers);

        if (!headers.has("Content-Type") && !(rqOptions?.body instanceof FormData)) {
          headers.set("Content-Type", "application/json");
        }

        headers.append("Authorization", `Bearer ${get(DEFAULT_TOKEN_KEY)}`);

        return fromFetch(`${options.endpoint}${url}`, {
          method: "POST",
          body: rqOptions?.body,
          headers: headers,
        }).pipe(
          concatMap((response) => {
            return this.handleReponse(response);
          }),
          map(
            (data: any): T => {
              this.throwMessage(data);

              return data;
            },
          ),
        );
      },
      put<T>(url: string, rqOptions?: RequestInit) {
        const headers = new Headers(rqOptions?.headers);

        if (!headers.has("Content-Type") && !(rqOptions?.body instanceof FormData)) {
          headers.set("Content-Type", "application/json");
        }

        headers.append("Authorization", `Bearer ${get(DEFAULT_TOKEN_KEY)}`);

        return fromFetch(`${options.endpoint}${url}`, {
          method: "PUT",
          body: rqOptions?.body,
          headers: headers,
        }).pipe(
          concatMap((response) => {
            return this.handleReponse(response);
          }),
          map(
            (data: any): T => {
              this.throwMessage(data);

              return data;
            },
          ),
        );
      },
      delete<T>(url: string, rqOptions?: RequestInit) {
        const headers = new Headers(rqOptions?.headers);

        if (!headers?.has("Content-Type") && !(rqOptions?.body instanceof FormData)) {
          headers.set("Content-Type", "application/json");
        }

        headers.append("Authorization", `Bearer ${get(DEFAULT_TOKEN_KEY)}`);

        return fromFetch(`${options.endpoint}${url}`, {
          method: "DELETE",
          body: rqOptions?.body,
          headers: headers,
        }).pipe(
          concatMap((response) => {
            return this.handleReponse(response);
          }),
          map(
            (data: any): T => {
              this.throwMessage(data);

              return data;
            },
          ),
        );
      },
    };
  },
};
