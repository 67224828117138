import { defineComponent, PropType } from "vue";

export const FilterSelect = defineComponent({
  name: "pw-fselect",
  emits: ["update:modelValue"],
  props: {
    options: { type: Object as PropType<Array<{ key: string; text: string }>>, required: true },
    name: { type: String },
    tabIndex: { type: Number },
    modelValue: { type: String, defaultValue: "" },
    label: { type: String },
    required: { type: Boolean },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    error: { type: Object as PropType<{ message: string; type?: string }> },
    defaultValue: { type: String, default: "" },
    onChange: { type: Function },
  },
  data() {
    return { filterText: "", displayData: [] as any[], isClickToInput: false, isFocus: false, current: "" };
  },
  watch: {
    modelValue() {
      if (!this.modelValue) {
        this.filterText = this.defaultValue;
      }

      this.setFilterTextToDefault();
    },
    isFocus(value: boolean) {
      if (value) {
        this.displayData = [...this.options];
        return;
      }

      this.setFilterTextToDefault();
    },
    options(values: any[]) {
      this.displayData = [...values];

      if (!this.modelValue) {
        this.filterText = this.defaultValue;
      }

      this.setFilterTextToDefault();
    },
    filterText(value: string) {
      if (value === "" && this.modelValue) {
        return;
      }

      if (value === "") {
        this.displayData = [...this.options];

        if (!this.required) {
          this.$emit("update:modelValue", "");
        }
        return;
      }

      this.displayData = this.options.filter((item) => item.text.search(value) > -1);
    },
  },
  mounted() {
    if (this.options && this.options.length > 0) {
      this.setFilterTextToDefault();
    }

    document.addEventListener("mousedown", this.outsideClick);
    document.addEventListener("touchstart", this.outsideClick);
  },
  unmounted() {
    document.removeEventListener("mousedown", this.outsideClick);
    document.removeEventListener("touchstart", this.outsideClick);
  },
  methods: {
    setFilterTextToDefault() {
      this.filterText = this.options.filter((o) => o.key === this.modelValue)[0]?.text ?? "";
    },
    click(o: { key: string; text: string }) {
      this.$emit("update:modelValue", o.key);
      this.onChange?.(o.key);
      this.filterText = o.text;
      setTimeout(() => (this.isFocus = false), 0);
    },
    outsideClick(event: Event) {
      if (!event.target) {
        return;
      }

      const item = this.options.find((item) => item.text === this.filterText);

      if (item) {
        this.$emit("update:modelValue", item.key);
      }

      const classlist = (event.target as HTMLElement).classList;
      if (!classlist.contains("pw-se")) {
        this.isFocus = false;
      }
    },
  },
  render() {
    return (
      <div class="w-full">
        {this.label && (
          <label class="flex flex-start mb-2">
            <span class="text-default-600 font-semibold text-sm">{this.label}</span>
            {this.required && <span class="text-red-600">&nbsp;*</span>}
          </label>
        )}
        <div
          class={`relative border border-solid ${this.isFocus ? "border-primary-600" : "border-gray-300"} ${
            this.disabled ? "bg-default-100" : ""
          } rounded w-full h-12 outline-none pw-se`}
          tabindex={this.tabIndex ?? -1}
        >
          <i
            class="ms-Icon ms-Icon--ChevronDown absolute cursor-pointer pw-se"
            style={{
              top: "1rem",
              right: "1rem",
              transition: "all 1s cubic-bezier(0.075, 0.82, 0.165, 1)",
              transform: this.isFocus && !this.readonly ? "rotate(180deg)" : "unset",
              zIndex: 99,
            }}
            onClick={() => {
              if (this.disabled) {
                return;
              }

              if (this.readonly) {
                return;
              }

              this.isFocus = !this.isFocus;
            }}
          />
          <input
            type="text"
            class="w-full h-full pl-4 py-4 rounded search-box pw-se bg-white"
            onFocus={() => (this.isFocus = true)}
            v-model={this.filterText}
            name="filter"
            autocomplete="off"
          />
          <ul
            class="w-full absolute py-4 pw-se"
            style={{
              display: this.isFocus && !this.readonly ? "block" : "none",
              top: "3rem",
              left: 0,
              right: 0,
              backgroundColor: "#fff",
              boxShadow: "5px 8px 14px 0 rgba(0, 0, 0, 0.17)",
              zIndex: 1000,
            }}
          >
            {this.displayData.map((o) => (
              <li
                key={o.key}
                class="h-10 leading-normal px-4 py-2 text-left hover:bg-gray-100 relative cursor-pointer pw-se"
                onClick={(e) => this.click(o)}
              >
                <span class="pw-se">{o.text}</span>
                {!!this.modelValue && this.modelValue !== "" && o.key === this.modelValue && (
                  <i
                    class="ms-Icon ms-Icon--CheckMark absolute text-primary-600 leading-none pw-se"
                    style={{ top: "0.75rem", right: "0.75rem" }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
        {this.error && <div class="text-sm text-red-600 text-left mt-2 leading-4">{this.error.message}</div>}
      </div>
    );
  },
});
