import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Private",
    component: () => import("../layouts/Private"),
    children: [
      {
        path: "",
        redirect: "sponsorships",
      },
      {
        path: "notifications",
        component: () => import("../views/Notifications"),
      },
      {
        path: "employees",
        component: () => import("../views/Employees"),
      },
      {
        path: "help",
        component: () => import("../views/Help"),
      },
      {
        path: "conversions",
        component: () => import("../views/Conversions"),
      },
    ],
  },
  {
    path: "/participants",
    component: () => import("../layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("../views/Participants"),
      },
      {
        path: "trip/:id",
        component: () => import("../views/trip/Trip"),
      },
    ],
  },
  {
    path: "/affiliate",
    component: () => import("../layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("../views/affiliate/Affiliate"),
      },
      {
        path: ":id",
        component: () => import("../layouts/Affiliate"),
        children: [
          {
            path: "details",
            component: () => import("../views/affiliate/affiliate/detail/AffilliateDetail"),
          },
          {
            path: "levels",
            component: () => import("../views/affiliate/affiliate/level/Levels"),
          },
          {
            path: "members",
            component: () => import("../layouts/EmptyLayout"),
            children: [
              {
                path: "",
                component: () => import("../views/affiliate/affiliate/member/Members"),
                name: "all-member",
              },
              {
                path: ":memberId",
                component: () => import("../layouts/EmptyLayout"),
                children: [
                  {
                    path: "edit",
                    component: () => import("../views/affiliate/affiliate/member/MemberForm"),
                  },
                ],
              },
              {
                path: "create",
                component: () => import("../views/affiliate/affiliate/member/MemberForm"),
                name: "create-member",
              },
            ],
          },
          {
            path: "members/create",
            component: () => import("../views/affiliate/affiliate/member/MemberForm"),
            name: "create-member",
          },
        ],
      },
      {
        path: ":territoryId/site",
        component: () => import("../views/affiliate/territory/site/Site"),
      },
    ],
  },
  {
    path: "/sponsorships",
    component: () => import("../layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("@/views/SponsorshipList"),
      },
      {
        path: ":id",
        component: () => import("../layouts/Sponsorship"),
        children: [
          {
            path: "details",
            component: () => import("../views/sponsorship/details/SponsorshipDetails"),
          },
          {
            path: "activity",
            component: () => import("../views/sponsorship/activity/Activity"),
          },
          {
            path: "coupon",
            component: () => import("@/views//sponsorship/coupon/Coupon"),
          },
          {
            path: "audience",
            component: () => import("../views/sponsorship/audience/Audience"),
          },
          {
            path: "planning",
            component: () => import("../views/sponsorship/planning/Planning"),
          },
          {
            path: "delivery",
            component: () => import("../views/sponsorship/delivery/Delivery"),
            children: [
              {
                path: "direction",
                component: () => import("../views/sponsorship/delivery/direction/Directions"),
              },
              {
                path: "zone",
                component: () => import("../views/sponsorship/delivery/zone/Zones"),
              },
            ],
          },
          {
            path: "participants",
            component: () => import("@/views/sponsorship/participants/Participants"),
          },
          {
            path: "statistics",
            component: () => import("../views/sponsorship/statistic/Statistics"),
          },
          {
            path: "payout",
            component: () => import("../views/sponsorship/payout/PayOut"),
          },
          {
            path: "referral-code",
            component: () => import("../views/sponsorship/referral-code/ReferralCode"),
          },
          {
            path: "survey-result",
            component: () => import("../views/sponsorship/survey-result/SurveyResult"),
          },
          {
            path: "log",
            name: "sponsor-log",
            component: () => import("../views/sponsorship/log/Log"),
          },
          {
            path: "cta",
            component: () => import("../views/sponsorship/cta/SponsorshipCTA"),
          },
          {
            path: "video-capture",
            component: () => import("../views/sponsorship/video-capture/VideoCapture"),
          },
        ],
      },
    ],
  },
  {
    path: "/users",
    component: () => import("@/layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("@/views/Users"),
      },
      {
        path: ":id",
        name: "UserDetail",
        component: () => import("@/views/user/UserDetail"),
      },
    ],
  },
  {
    path: "/finance",
    component: () => import("@/layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("@/layouts/Finance"),
        children: [
          {
            path: "conversion-rate",
            component: () => import("@/views/finance/conversion-rate/ConversionRate"),
          },
          {
            path: "promotions",
            component: () => import("@/views/finance/promotions/Promotions"),
          },
          {
            path: "transactions",
            component: () => import("@/views/finance/transactions/TransferOut"),
          },
          {
            path: "currency",
            component: () => import("@/views/finance/currency/Currency"),
          },
        ],
      },
    ],
  },
  {
    path: "/insights",
    component: () => import("@/layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("@/layouts/Insights"),
        children: [
          {
            path: "survey",
            name: "insights-survey",
            component: () => import("@/views/insights/survey/Survey"),
          },
          {
            path: "manage",
            component: () => import("@/views/insights/manage/Manage"),
          },
        ],
      },
    ],
  },
  {
    path: "/referrals",
    component: () => import("@/layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("@/layouts/Referrals"),
        children: [
          {
            path: "levels",
            component: () => import("@/views/referrals/level/ReferralLevels"),
          },
          {
            path: "tracker",
            component: () => import("@/views/referrals/tracker/ReferralTracker"),
          },
        ],
      },
    ],
  },
  {
    path: "/partners",
    component: () => import("../layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("@/views/PartnerList"),
      },
      {
        path: ":partnerId",
        component: () => import("../layouts/Partner"),
        children: [
          {
            path: "details",
            component: () => import("../views/partner/CompanyDetails"),
          },
          {
            path: "employees",
            component: () => import("../views/partner/EmpList"),
          },
          {
            path: "locations",
            component: () => import("../views/partner/LocationList"),
          },
          {
            path: "sponsorships",
            component: () => import("@/views/partner/SponsorshipList"),
          },
          {
            path: "sponsorships/:id",
            component: () => import("@/layouts/partner/Sponsorship"),
            children: [
              {
                path: "details",
                component: () => import("../views/sponsorship/details/SponsorshipDetails"),
                name: "SponsorshipDetail",
              },
              {
                path: "activity",
                component: () => import("../views/sponsorship/activity/Activity"),
              },
              {
                path: "audience",
                component: () => import("../views/sponsorship/audience/Audience"),
              },
              {
                path: "planning",
                component: () => import("../views/sponsorship/planning/Planning"),
              },
              {
                path: "delivery",
                component: () => import("../views/sponsorship/delivery/Delivery"),
                children: [
                  {
                    path: "direction",
                    component: () => import("../views/sponsorship/delivery/direction/Directions"),
                  },
                  {
                    path: "zone",
                    component: () => import("../views/sponsorship/delivery/zone/Zones"),
                  },
                ],
              },
              {
                path: "participants",
                component: () => import("@/views/sponsorship/participants/Participants"),
              },
              {
                path: "statistics",
                component: () => import("../views/sponsorship/statistic/Statistics"),
              },
              {
                path: "payout",
                component: () => import("../views/sponsorship/payout/PayOut"),
              },
              {
                path: "referral-code",
                component: () => import("../views/sponsorship/referral-code/ReferralCode"),
              },
              {
                path: "survey-result",
                component: () => import("../views/sponsorship/survey-result/SurveyResult"),
              },
              {
                path: "log",
                name: "sponsor-log-partner",
                component: () => import("../views/sponsorship/log/Log"),
              },
              {
                path: "cta",
                component: () => import("../views/sponsorship/cta/SponsorshipCTA"),
              },
              {
                path: "video-capture",
                component: () => import("../views/sponsorship/video-capture/VideoCapture"),
              },
            ],
          },
          {
            path: "instant-insights",
            component: () => import("@/views/partner/InsightSponsorship"),
          },
          {
            path: "instant-insights/:id",
            component: () => import("@/layouts/partner/InsightSponsorship"),
            children: [
              {
                path: "details",
                component: () => import("../views/insight-sponsorship/details/SponsorshipDetails"),
              },
              {
                path: "activity",
                component: () => import("../views/insight-sponsorship/activity/Activity"),
              },
              {
                path: "coupon",
                component: () => import("@/views//insight-sponsorship/coupon/Coupon"),
              },
              {
                path: "audience",
                component: () => import("../views/insight-sponsorship/audience/Audience"),
              },
              {
                path: "planning",
                component: () => import("../views/insight-sponsorship/planning/Planning"),
              },
              {
                path: "delivery",
                component: () => import("../views/insight-sponsorship/delivery/Delivery"),
                children: [
                  {
                    path: "direction",
                    component: () => import("../views/insight-sponsorship/delivery/direction/Directions"),
                  },
                  {
                    path: "zone",
                    component: () => import("../views/insight-sponsorship/delivery/zone/Zones"),
                  },
                ],
              },
              {
                path: "participants",
                component: () => import("@/views/insight-sponsorship/participants/Participants"),
              },
              {
                path: "statistics",
                component: () => import("../views/insight-sponsorship/statistic/Statistics"),
              },
              {
                path: "payout",
                component: () => import("../views/insight-sponsorship/payout/PayOut"),
              },
              {
                path: "referral-code",
                component: () => import("../views/insight-sponsorship/referral-code/ReferralCode"),
              },
              {
                path: "survey-result",
                component: () => import("../views/insight-sponsorship/survey-result/SurveyResult"),
              },
              {
                path: "log",
                name: "sponsor-log",
                component: () => import("../views/insight-sponsorship/log/Log"),
              },
              {
                path: "cta",
                component: () => import("../views/insight-sponsorship/cta/SponsorshipCTA"),
              },
              {
                path: "video-capture",
                component: () => import("../views/insight-sponsorship/video-capture/VideoCapture"),
              },
              {
                path: "app-messages",
                component: () => import("../views/insight-sponsorship/app-message/AppMessage"),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/notifications",
    component: () => import("../layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("../views/Notifications"),
      },
      {
        path: ":id",
        component: () => import("../layouts/Notification"),
        children: [
          {
            name: "notificationDetail",
            path: "details",
            component: () => import("../views/notification/NotificationDetails"),
          },
        ],
      },
      {
        path: "push",
        component: () => import("../views/notification/Push"),
      },
    ],
  },
  {
    path: "/settings",
    component: () => import("../layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("../layouts/UserProfiles"),
        children: [
          {
            path: "",
            redirect: "/settings/occupations",
          },
          {
            path: "educations",
            component: () => import("../views/settings/Educations"),
          },
          {
            path: "occupations",
            component: () => import("../views/settings/Occupations"),
          },
          {
            path: "ethnicities",
            component: () => import("../views/settings/Ethnicities"),
          },
          {
            path: "company-types",
            component: () => import("../views/settings/CompanyTypes"),
          },
          {
            path: "employments",
            component: () => import("../views/settings/Employments"),
          },
          {
            path: "age-ranges",
            component: () => import("../views/settings/AgeRanges"),
          },
          {
            path: "household-income",
            component: () => import("../views/settings/IncomeRanges"),
          },
          {
            path: "video-capture",
            component: () => import("../views/settings/VideoCapture"),
          },
        ],
      },
    ],
  },
  {
    path: "/log",
    component: () => import("@/layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("@/views/Log"),
        name: "log-page",
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store.state.auth.isAuthenticated) {
    return next({ name: "Login" });
  }
  if (to.name === "Login" && store.state.auth.isAuthenticated) {
    return next({ name: "Private" });
  }
  next();
});

export default router;
