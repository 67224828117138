import { defineComponent } from "vue";

export const RangeSlider = defineComponent({
  name: "RangeValue",
  emits: ["update:modelValue", "change"],
  props: {
    name: { type: String },
    label: { type: String },
    required: { type: Boolean },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    max: { type: Number, required: true, default: 100 },
    min: { type: Number, required: true, default: 0 },
    modelValue: { type: [String, Number] },
    onRenderLabel: { type: Function },
    useOnInput: { type: Boolean, defaultValue: false },
  },
  computed: {
    sliderRange() {
      const currentValue = Number(this.modelValue ?? 0);
      return (currentValue / this.max) * 100;
    },
  },
  methods: {
    onChange(e: Event) {
      const currentValue = Number((e.target as HTMLInputElement).value);
      this.$emit("update:modelValue", currentValue);
    },
  },
  render() {
    return (
      <div class="w-full flex flex-col">
        {this.onRenderLabel ? (
          this.onRenderLabel(this.$props)
        ) : this.label ? (
          <label class="flex flex-start mb-2">
            <span class="text-default-600 font-semibold text-sm">{this.label}</span>
            {this.required && <span class="text-red-600">&nbsp;*</span>}
          </label>
        ) : null}
        <div tabindex={-1} class="range-slider relative">
          <span
            style={{
              width: `${this.sliderRange}%`,
              display: this.sliderRange === 0 ? "none" : "block",
            }}
            class="slide"
          ></span>
          <input
            type="range"
            name={this.name}
            value={this.modelValue}
            onInput={this.onChange}
            disabled={this.disabled}
            readonly={this.readonly}
            max={this.max}
            min={this.min}
          />
        </div>
      </div>
    );
  },
});
