import { defineComponent } from "vue";

export const ToogleSwitch = defineComponent({
  name: "pw-switch",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      defaultValue: false,
      required: false,
    },
    label: {
      type: String,
      defaultValue: "",
      required: false,
    },
    disabled: {
      type: Boolean,
      defaultValue: false,
    },
    key: {
      type: String,
      required: false,
    },
    onChange: {
      type: Function,
      required: false,
    },
  },
  methods: {
    handleChange(event: any) {
      if (this.disabled) {
        return;
      }

      if (this.onChange) {
        this.onChange(event, this.$emit);
        return;
      }

      this.$emit("update:modelValue", event.target.checked);
    },
  },
  render() {
    return (
      <label class="switch w-12 h-6" onClick={(e) => e.stopPropagation()} for={this.key}>
        {this.label}
        <input id={this.key} type="checkbox" disabled={this.disabled} checked={this.modelValue} onChange={this.handleChange} />
        <span class="slider round"></span>
      </label>
    );
  },
});
