import { defineComponent } from "vue";

export const CommaTextbox = defineComponent({
  name: "CommaTextbox",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
      required: false,
    },
    name: {
      type: String,
      default: "",
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
      required: false,
    },
    controlClass: {
      type: String,
      default: "",
      required: false,
    },
    label: {
      type: String,
      default: "",
      required: false,
    },
    prefix: {
      type: String,
      default: "",
      required: false,
    },
    comma: {
      type: String,
      default: ",",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
    },
    error: {
      type: Object,
    },
  },
  computed: {
    displayValue: {
      get(): string {
        return `${this.modelValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.comma)}`;
      },
      set(value: string) {
        const pre = this.prefix?.toString();
        const emitValue = value.replaceAll(this.comma, "").replaceAll(pre!, "");

        if (!emitValue || emitValue === "0") {
          return this.$emit("update:modelValue", 0);
        }

        if (!Number(emitValue)) {
          return this.$emit("update:modelValue", this.modelValue);
        }

        return this.$emit("update:modelValue", Number(emitValue));
      },
    },
  },
  render() {
    return (
      <div>
        <w-input
          type="text"
          label={this.label}
          value={this.displayValue}
          class={`${this.controlClass}`}
          placeholder={this.placeholder}
          name={this.name}
          v-model={this.displayValue}
          disabled={this.disabled}
          prefix={this.prefix}
          readonly={this.readonly}
          required={this.required}
        />
        {this.error && <div class="text-sm text-red-600 text-left mt-2">{this.error.message}</div>}
      </div>
    );
  },
});
