import { get, set, remove } from "js-cookie";
import decode from "jwt-decode";
import { Account, Auth } from "@/types/auth";
const DEFAULT_TOKEN_KEY = "auth.token";
const DEFAULT_LOGIN_URL = "/admin/login";

export default {
  install: (app: any, options: any) => {
    const $store = app.config.globalProperties.$store;
    const $router = app.config.globalProperties.$router;
    const auth = $store.state.auth;

    const token = get(DEFAULT_TOKEN_KEY);
    if (!!token && token !== "") {
      const user = decode<any>(token);
      $store.commit("setAuth", {
        ...auth,
        ...{
          isAuthenticated: !!token && token !== "",
          token: token,
          user: {
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
          },
        },
      });
    }

    app.mixin({
      mutations: {
        setAuth: (state: any, auth: any) => {
          state.auth = auth;
        },
      },
    });

    app.config.globalProperties.$auth = {
      login: async (account: Account, errorCallBack?: (message: string) => void): Promise<void> => {
        try {
          let response = await fetch(`${options.endpoint}${DEFAULT_LOGIN_URL}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: account.username,
              password: account.password,
            }),
          });
          if (!response.ok) {
            errorCallBack?.(response.statusText);
            return;
          }

          const token = (await response.json()).data.token;
          set(DEFAULT_TOKEN_KEY, token);

          // if (!!token && token !== "") {
          //   const user = decode<any>(token);
          //   $store.commit("setAuth", {
          //     ...auth,
          //     ...{
          //       isAuthenticated: !!token && token !== "",
          //       token: token,
          //       user: {
          //         id: user.id,
          //         firstName: user.first_name,
          //         lastName: user.last_name,
          //       },
          //     },
          //   });
          // }

          response = await fetch(`${options.endpoint}/admin/auth`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            errorCallBack?.(response.statusText);
            return;
          }

          const user = (await response.json()).data;
          $store.commit("setAuth", {
            ...auth,
            ...{
              isAuthenticated: true,
              token: token,
              user: {
                id: user.id,
                firstName: user.first_name,
                lastName: user.last_name,
              },
            },
          });

          $router.push("/");
        } catch (e: any) {
          errorCallBack?.(e.message);
          console.error(e);
        }
      },

      logout: (): void => {
        $store.commit("setAuth", { isAuthenticated: false });
        remove(DEFAULT_TOKEN_KEY);
        $router.push("/login");
      },
    } as Auth;
  },
};
