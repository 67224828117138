import { KeyValue } from "@/types/sponsorship";
import { format } from "date-fns";
import { createStore } from "vuex";

export default createStore({
  state: {
    auth: {
      // isAuthenticated: true,
      isAuthenticated: false,
    },
    territories: [] as { id: number; items: KeyValue[] }[],
    files: [
      {
        code: "",
        link: "",
      },
    ],
    codeUsages: {
      id: -9999,
      data: [],
      page: 1,
      lastPage: 1,
    },
    code: {
      id: -9999,
      code: "",
    },
    planning: {
      coupon: {
        id: -9999,
        summary: {
          available: 0,
          total: 0,
          today: 0,
          week: 0,
          month: 0,
        },
      },
    },
    // Save prev state when redirect to other page,
    // Use to keep current state when back to page
    prevState: {
      sponsorships: {
        filters: {
          partner: "-2",
          status: "-2",
          state: "-2",
          isQA: "",
          type: "-2",
        },
        page: 1,
        sort: {
          field: "",
          type: "",
        },
      },
      sponsorshipParticipant: {
        id: "",
        name: "",
        startDate: "",
        endDate: "",
      },
      insightSponsorships: {
        filters: {
          partner: "-2",
          status: "-2",
          state: "-2",
          isQA: "",
          type: "-2",
        },
        page: 1,
        sort: {
          field: "",
          type: "",
        },
      },
      insightSponsorshipParticipant: {
        id: "",
        name: "",
        startDate: "",
        endDate: "",
      },
      partners: {
        filters: {
          name: "",
          status: "-2",
        },
        page: 1,
      },
      partnerSponsorships: {
        filters: {
          partner: "-2",
          status: "-2",
          state: "-2",
          isQA: "",
          type: "-2",
        },
        page: 1,
        sort: {
          field: "",
          type: "",
        },
      },
      users: {
        page: 1,
        filters: {
          rank: "-1",
          name: "",
          email: "",
          fromDate: "",
          endDate: "",
        },
      },
      participants: {
        page: 1,
        startDate: format(new Date(2020, 0, 1), "YYYY-MM-DD"),
        endDate: format(new Date(), "YYYY-MM-DD"),
      },
      tranferOut: {
        tab: 1,
        transactionHistory: {
          page: 1,
          filters: {
            source: "0",
            code: "",
            status: "-1",
            email: "",
            userId: "",
          },
        },
        transactionSetting: {
          page: 1,
        },
        transactionRequest: {
          page: 1,
          filters: {
            status: "-1",
            userId: "",
            approval: "",
            endDate: "",
            isManual: "-1",
            startDate: "",
          },
        },
      },
      userDetail: {
        referral: {
          page: 1,
          lastPage: 1,
          filters: {
            id: "",
            email: "",
          },
        },
        withdrawal: {
          page: 1,
          lastPage: 1,
          filters: {
            source: "0",
            code: "",
            status: "-1",
          },
        },
        currentTab: 1,
        paytrippaPoints: {
          lastPage: 1,
          page: 1,
          filters: {
            source: "0",
            fromDate: "",
            endDate: "",
          },
        },
        userReferral: {
          lastPage: 1,
          page: 1,
          filters: {
            rankId: "-1",
            name: "",
            startDate: "",
            endDate: "",
          },
        },
        trips: {
          page: 1,
          lastPage: 1,
        },
      },
      conversionHistory: {
        page: 1,
        filters: {
          userId: "",
          source: "0",
          fromDate: "",
          endDate: "",
        },
      },
      referralTracker: {
        filters: {
          rank: "-1",
          name: "",
          fromDate: "",
          endDate: "",
        },
        page: 1,
      },
      affiliate: {
        affiliate: {
          page: 1,
        },
      },
    },
  },
  mutations: {
    setAuth(state, auth) {
      state.auth = auth;
    },
    setCodeUsages(state, usageData) {
      state.codeUsages = { ...usageData };
    },
    setCode(state, code) {
      state.code = { ...code };
    },
    setFiles(state, file) {
      state.files = [...state.files, file];
    },
    setPrevState(state, newState) {
      state.prevState = { ...state.prevState, ...newState };
    },
    setTerritoriesItem(state, newState) {
      state.territories = [...newState];
    },
    setPlanningTab(state, planning) {
      state.planning = { ...state.planning, ...planning };
    },
  },
  actions: {},
  modules: {},
});
