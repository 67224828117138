import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TextArea",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      defaultValue: "",
      required: false,
    },
    name: {
      type: String,
      defaultValue: "",
      required: false,
    },
    id: {
      type: String,
      defaultValue: "",
      required: false,
    },
    placeholder: {
      type: String,
      defaultValue: "",
      required: false,
    },
    controlClass: {
      type: String,
      defaultValue: "",
      required: false,
    },
    cols: {
      type: Number,
      defaultValue: "30",
      required: false,
    },
    rows: {
      type: Number,
      defaultValue: "10",
      required: false,
    },
    label: {
      type: String,
      defaultValue: "",
      required: false,
    },
    length: {
      type: Number,
    },
    required: { type: Boolean },
    disabled: {
      type: Boolean,
    },
    error: { type: Object as PropType<{ message: string; type?: string }> },
    onRenderLabel: {
      type: Function,
      required: false,
    },
  },
  methods: {
    handleInput(event: any) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  render() {
    return (
      <div>
        <label class="text-default-600 font-semibold text-sm">
          {this.label}
          {this.required && <span class="text-red-600">&nbsp;*</span>}
          {this.onRenderLabel?.()}
        </label>
        <textarea
          value={this.modelValue}
          placeholder={this.placeholder}
          name={this.name}
          id={this.id}
          class={`w-full rounded-md px-3 py-4 leading-normal input-border mt-1 ${this.controlClass}`}
          cols={this.cols}
          rows={this.rows}
          onInput={this.handleInput}
          maxlength={this.length}
          disabled={this.disabled}
        ></textarea>
        {this.error && <div class="text-sm text-red-600 text-left mt-2 leading-4">{this.error.message}</div>}
      </div>
    );
  },
});
